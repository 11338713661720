
const body = document.querySelector('body');

const hamburger = document.querySelector('.mob-icon');
const nav = document.querySelector('.main-nav');
const navWrapper = document.querySelector('.nav-wrapper');
const loader = document.querySelector('.loader');
const modalPop = document.querySelectorAll('.modal-pop');
const closePop = document.querySelectorAll('.close');
const links = document.querySelectorAll('.scroll-to');
const wrapper = document.querySelector('#wrapper');
const home = document.querySelector('#home');
const about = document.querySelector('#about');
const globe = document.querySelector('#globe-keys');
const products = document.querySelector('#products');
const sustainability = document.querySelector('#sustainability');
const services = document.querySelector('#services');
const contact = document.querySelector('#contact');
const footprints = document.querySelector('#footprints');
const dots = document.querySelectorAll('.dot');
const stars = document.querySelectorAll('.star');
const btn = document.querySelectorAll('.btn');
const carousel = document.querySelector('#carousel');
const pins = document.querySelectorAll('.pin');

window.addEventListener('load', (event) => {
  body.classList.remove('no-scroll');
  setTimeout(() => {
    body.removeChild(loader)
  }, 10)
});

console.log(window.location.hash);

/* Simple check for popular Mobile devices */
function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

if(isMobile()) {
  body.classList.add('isMobile');
}

/* Detect element is in view */
function isElementInViewport (el) {
  var rect = el.getBoundingClientRect();
  // console.log( window.innerHeight )
  // console.log( rect.top )
  return (
      rect.top >= -100 &&
      rect.top <= (window.innerHeight * 0.66)
      // &&
      // rect.left >= 0 &&
      //rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
      //rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
  );
}

/* Throttle the scroll/resize listener */
function throttle(func, timeFrame) {
  var lastTime = 0;
  return function () {
      var now = new Date();
      if (now - lastTime >= timeFrame) {
          func();
          lastTime = now;
      }
  };
}

/* Wrapper Scroll listener */
wrapper.onscroll = throttle(() => {
    // console.log('called');
    // console.log('home', isElementInViewport(home));
    if(isElementInViewport(home)) {
      links.forEach((v) => { v.classList.remove('active') });
      // document.querySelector('.home').classList.add('active');
      if ( !isMobile() ) { nav.classList.remove('scroll') };
    }
    // console.log('about', isElementInViewport(about));
    if(isElementInViewport(about) || isElementInViewport(footprints) || isElementInViewport(story)) {
      links.forEach((v) => { v.classList.remove('active') });
      document.querySelector('.about').classList.add('active');
      if ( !isMobile() ) { nav.classList.add('scroll') };
    }
    if(isElementInViewport(products)) {
      links.forEach((v) => { v.classList.remove('active') });
      document.querySelector('.products').classList.add('active');
      if ( !isMobile() ) { nav.classList.add('scroll') };
    }
    if(isElementInViewport(quality) || isElementInViewport(sustainability)) {
      links.forEach((v) => { v.classList.remove('active') });
      document.querySelector('.sustainability').classList.add('active');
      if ( !isMobile() ) { nav.classList.add('scroll') };
    }
    if(isElementInViewport(contact)) {
      links.forEach((v) => { v.classList.remove('active') });
      document.querySelector('.contact').classList.add('active');
      if ( !isMobile() ) { nav.classList.add('scroll') };
    }
    if(isElementInViewport(services)) {
      links.forEach((v) => { v.classList.remove('active') });
      document.querySelector('.services').classList.add('active');
      if ( !isMobile() ) { nav.classList.add('scroll') };
    }

    // console.log('globe', isElementInViewport(globe));
    if(isElementInViewport(globe)) {
      pins.forEach((v) => {
        v.classList.add('bouncePin');
      })
    }

    if(isElementInViewport(footprints)) {
      // links.forEach((v) => { v.classList.remove('active') });
      if ( !isMobile() ) { nav.classList.add('scroll') };
      dots.forEach((v) => {
        v.classList.add('bounceIn');
      })
      stars.forEach((v) => {
        v.classList.add('starIn');
      })
    }
    if(isElementInViewport(story)) {
        // links.forEach((v) => { v.classList.remove('active') });
        // document.querySelector('.story').classList.add('active');
      if ( !isMobile() ) { nav.classList.add('scroll') };
    }
  // }, 100)


  // wrapper.onscroll = throttle(() => {
    if(isMobile()) {
      let currentScrollPos = wrapper.scrollTop;
      if (prevScrollpos > currentScrollPos && currentScrollPos > 100) {
        navWrapper.classList.add('scroll');
      } else {
        navWrapper.classList.remove('scroll');
      }
      prevScrollpos = currentScrollPos;
    }
  }, 100)

  let prevScrollpos = wrapper.scrollTop;


  







/* Modal pop-open event listner for any tag with the class name .modal-pop */
// for (const modalLink of modalPop) {
//   modalLink.addEventListener('click', (e) => {
//     e.preventDefault();
//     populateData(e.target.getAttribute('data-prod'));
//     overlay.classList.add('launch');
//     setTimeout( () => {
//       body.classList.add('open');
//     },0)
//   })
// }

/* Modal close event listner for any tag with the class name .close */
// for (const close of closePop) {
//   close.addEventListener('click', (e) => {
//     body.classList.remove('open');
//     setTimeout( () => {
//       overlay.classList.remove('launch');
//     },300)
//   })
// }

/* Scroll to event listner for <a> tags that have the class name .scroll-to */
for (const link of links) {
  
  link.addEventListener('click', (e) => {
    e.preventDefault();
  

    var el = e.target;
    while (el && el.tagName !== "A") {
        el = el.parentNode;
    }
    console.log(el);

    // links.forEach((v) => { v.classList.remove('active') });
    // e.target.add('active');
    const href = el.getAttribute('href');
    console.log(href);
    const offsetTop = document.querySelector(href).offsetTop;

    // console.log('OffsetTop', offsetTop - 100)
  
    wrapper.scroll({
      top: offsetTop,
      behavior: 'smooth'
    });
  })
}

/* Button listeners */
btn.forEach((v) => {
  v.addEventListener('click', (e) => {
    e.preventDefault();
    const elm = e.target.getAttribute('id');
    switch(elm) {
      case 'about_global':
          document.querySelector('.about_global').style.display = 'block';
          document.querySelector('.about_au').style.display = 'none';
          document.querySelector('#about_global').classList.remove('button--white');
          document.querySelector('#about_global').classList.add('button--red');
          document.querySelector('#about_au').classList.remove('button--red');
          document.querySelector('#about_au').classList.add('button--white');
        break;
      case 'about_au':
          document.querySelector('.about_global').style.display = 'none';
          document.querySelector('.about_au').style.display = 'block';
          document.querySelector('#about_global').classList.remove('button--red');
          document.querySelector('#about_global').classList.add('button--white');
          document.querySelector('#about_au').classList.remove('button--white');
          document.querySelector('#about_au').classList.add('button--red');
        break;
      case 'map_nz':
          document.querySelector('.map_au').style.display = 'none';
          document.querySelector('.map_nz').style.display = 'block';
          document.querySelector('#map_au').classList.remove('button--red');
          document.querySelector('#map_au').classList.add('button--white');
          document.querySelector('#map_nz').classList.remove('button--white');
          document.querySelector('#map_nz').classList.add('button--red');
        break;
      case 'map_au':
          document.querySelector('.map_au').style.display = 'block';
          document.querySelector('.map_nz').style.display = 'none';
          document.querySelector('#map_au').classList.remove('button--white');
          document.querySelector('#map_au').classList.add('button--red');
          document.querySelector('#map_nz').classList.remove('button--red');
          document.querySelector('#map_nz').classList.add('button--white');
        break;
    }
  })
});

// let slidesToShow = 2;
// let slick = null;

// function buildCarousel() {
//   slick = new Slick(
//     carousel, 
//     {
//       infinite: false,
//       slidesToShow: slidesToShow,
//       slidesToScroll: 1,
//       dots: true,
//       prevArrow: '<div class="slick-prev slick-arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="#d50032" d="M238.475 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L50.053 256 245.546 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L10.454 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z"/></svg></div>',
//       nextArrow: '<div class="slick-next slick-arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="#d50032" d="M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z"/></svg></div>',
//     }
//   );
// }

// buildCarousel();

// function reportWindowSize() {
//   console.log(window.innerWidth);
//   if(window.innerWidth <= 992) {
//     let slidesToShow = 3;
//     slick.unslick();
//     buildCarousel()
//   } else if (window.innerWidth <= 600) {
//     let slidesToShow = 2;
//     slick.unslick();
//     buildCarousel()
//   }
// }

// window.onresize = reportWindowSize;



// const slick = new Slick(
//   carousel, 
$('#carousel').slick(
  {
    infinite: true,
    dots: true,
    appendDots: '.controls',
    prevArrow: '<div class="slick-prev slick-arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="#d50032" d="M238.475 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L50.053 256 245.546 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L10.454 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z"/></svg></div>',
    nextArrow: '<div class="slick-next slick-arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="#d50032" d="M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z"/></svg></div>',
    appendArrows: '.controls',
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }
);

let slickActive = false;

function reportWindowSize() {
  if(window.innerWidth <= 600 && !slickActive) {
    console.log('Mobile Size');
    $('#container-info').slick(
      {
        infinite: true,
        dots: true,
        appendDots: '.info-controls',
        prevArrow: '<div class="slick-prev slick-arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="#d50032" d="M238.475 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L50.053 256 245.546 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L10.454 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z"/></svg></div>',
        nextArrow: '<div class="slick-next slick-arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="#d50032" d="M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z"/></svg></div>',
        appendArrows: '.info-controls',
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    );
    $('#services-cards').slick(
      {
        infinite: true,
        dots: true,
        appendDots: '.services-controls',
        prevArrow: '<div class="slick-prev slick-arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="#d50032" d="M238.475 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L50.053 256 245.546 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L10.454 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z"/></svg></div>',
        nextArrow: '<div class="slick-next slick-arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="#d50032" d="M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z"/></svg></div>',
        appendArrows: '.services-controls',
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    );
    slickActive = true;
  } else if(window.innerWidth > 600 && slickActive) {
    $('#container-info').slick('unslick');
    $('#services-cards').slick('unslick');
    slickActive = false;
  }
  // console.log(window.innerWidth); 
}

reportWindowSize();

window.onresize = reportWindowSize;


document.querySelector("#year").innerHTML = new Date().getFullYear();

